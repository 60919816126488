import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"
import TopHat from "../components/tophat"
import Footer from "../components/footer"
import Content from "../components/content"
import SEO from "../components/seo"
import logo from "../images/logo.png"

const TaxPage = ({data, pageContext}) => {
    console.log(data)
    let rates = data.graphAPI.district.tax_rates
    return (
        <Layout>
            <SEO title="Tax Information" />
            <TopHat />
            <div className="main">
                <Header district={pageContext.district}  />
                <div className="main-content">
                    <div className="title-section">
                        <small style={{display: 'inline-block',paddingBottom: '1rem'}}>Hays WCID {pageContext.district}</small>
                        <h1 className="title is-size-1">Tax Information</h1>
                    </div>
                    <div className="content-align">
                        <p>Hays County WCID {pageContext.district} Ad Valorem Taxes rates are based on property values established by
                        the <a href="https://www.hayscad.com/" target="_blank" rel="noopener noreferrer">Hays County Appraisal District</a>. 
                        The tax rate (below) is per $100 of home value, as determined by the Hays County Appraisal District.</p>
                        <p><a className="button is-primary" href="https://hayscountytx.com/departments/taxoffice/" target="_blank" rel="noopener noreferrer">View, Print, or Pay your Taxes Online</a></p>
                        {pageContext.district === "1" ? (
                            <>
                            <p><a className="button is-primary" href="/assets/HCWCID_No._1_-_Final_-_2024_Notice_of_Public_Hearing_-_CL_4877-5833-6212_2.pdf" target="_blank" rel="noopener noreferrer">Notice of Public Hearing on Tax Rate for 2024 - Sep 12, 2024</a></p>
                            </>
                        ) : (
                            <p><a className="button is-primary" href="/assets/HCWCID_No._2_-_Notice_of_Public_Hearing_on_Tax_Rate_2024_4854-7879-0612_1.pdf" target="_blank" rel="noopener noreferrer">Notice of Public Hearing on Tax Rate for 2024 - Sep 19, 2024</a></p>
                        )}
                    <table className="table" style={{marginTop: '3rem'}}>
                        <thead>
                            <tr>
                                <td>Year</td>
                                <td>Debt Service</td>
                                <td>Maintenance</td>
                                <td>Total Tax Rate</td>
                                <td>Order Levying Taxes</td>
                            </tr>
                        </thead>
                        <tbody>
                            {rates.map(rate => (
                                <tr key={rate.id}>
                                    <td>{rate.year}</td>
                                    <td>{(rate.debtServiceRate).toFixed(3)}</td>
                                    <td>{(rate.MaintenanceRate).toFixed(3)}</td>
                                    <td>{(rate.debtServiceRate+rate.MaintenanceRate).toFixed(3)}</td>
                                    <td><a className="button is-primary is-small" target="_blank" rel="noreferrer noopener" href={rate.orderLevyingTaxes.documentFile.publicURL}>Order Levying Taxes – {rate.year}</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
                <Footer district={pageContext.district}></Footer>
            </div>
        </Layout>
    )
}

export default TaxPage

export const query = graphql`
    query taxPageById($district: ID!) {
        graphAPI {
            district(id: $district) {
                tax_rates {
                    year
                    MaintenanceRate
                    debtServiceRate
                    orderLevyingTaxes {
                        url
                        updated_at
                        documentFile {
                            publicURL
                            prettySize
                        }
                    }
                }
            }
        } 
    }
`


